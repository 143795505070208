import axios from "axios";

export const GET_ALL = "medics_self_registrations";

export function getAllInAuthorize(pageNumber) {
    return axios.get(`${GET_ALL}?page=${pageNumber}`);
}

export function getMedicById(medicId) {
    return axios.get(`${GET_ALL}/${medicId}`);
}

export function updateMedicById(medicId, authorized) {
    return axios.put(`${GET_ALL}/${medicId}?authorized=${authorized}`);
}


export function getAllInAuthorizeFilter(body, pageNumber) {
    return axios.post(`${GET_ALL}?page=${pageNumber}`, body);
}

export function getStates() {
    const { REACT_APP_POSTAL_CODE_MEX_BASE_URL, REACT_APP_POSTAL_CODE_MEX_API_KEY } = process.env;

    return axios.get(`${REACT_APP_POSTAL_CODE_MEX_BASE_URL}/estados`, {
        headers: {
            'Authorization': 'Bearer ' + REACT_APP_POSTAL_CODE_MEX_API_KEY,
        },
        skipInterceptor: true
    });
}
