export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (!config.skipInterceptor) {
            if (authToken) {
                config.headers = {'Content-Type': 'application/json;charset=UTF-8'}
                if (!config.headers.Authorization) {
                    config.headers.Authorization = `Bearer ${authToken}`;
                }
            }
            config.baseURL = process.env.REACT_APP_API_URL || "http://localhost:8080/api/"
      }
      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.request.use(function (config) {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
    return config
  }, function (error) {
      return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
      // spinning hide
      // UPDATE: Add this code to hide global loading indicator
      document.body.classList.remove('loading-indicator');
      return response;
  }, function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
  });
}
