import React, { useState, useEffect } from "react";
import { useSubheader } from "@metronic/layout";
import {Alert, Button, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import Pagination from "react-js-pagination";
import {getAllInAuthorize, getAllInAuthorizeFilter, getStates} from "../../services/medicsSelfRegistrationService";
import { getAll as getAllHospitals } from "@services/hospitalsService";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import AlertError from "../../../components/AlertError";
import {ErrorMessage, Formik} from "formik";
import { getInputClasses } from "@utils/getInputclasses";

const MedicSelfRegistrations = () => {
  const subheader = useSubheader();

  const [medics, setMedics] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [filters, setFilters] = useState({});
  const [states, setStates] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  useEffect(() => {
    getAllMedicsInAuthorize(paramsPagination.activePage);
    getAllHospitals().then((data) => setHospitals(data.data));
    getStates().then(data => setStates(data.data));

    subheader.setTitle("Solicitud de registros de medicos");
  }, []);

  const getAllMedicsInAuthorize = (pageNumber) => {
    getAllInAuthorize(pageNumber).then((response) => {
      setMedics(response.data?.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.data?.last_page,
        itemsCountPerPage: response.data?.per_page,
        totalItemsCount: response.data?.total,
      });
    });
  }

  const getAllMedicsInAuthorizeFilter = (body, pageNumber) => {
    getAllInAuthorizeFilter(body, pageNumber).then((response) => {
      setMedics(response.data?.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.data?.last_page,
        itemsCountPerPage: response.data?.per_page,
        totalItemsCount: response.data?.total,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (Object.keys(filters).length === 0) {
      getAllMedicsInAuthorize(pageNumber);
    } else {
      getAllMedicsInAuthorizeFilter({body: filters}, pageNumber);
    }
  }

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    const body = {
      'start_date': values.startDate,
      'end_date': values.endDate,
      'state': values.state,
    };

    setFilters(body);

    getAllMedicsInAuthorizeFilter(body, 1);

    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Solicitud de registros de medicos
      </Alert>

      <Formik
          initialValues={{
            startDate: "",
            endDate: "",
            hospital_id: "",
            state: "",
          }}
          onSubmit={handleSubmitForm}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            status,
            isSubmitting,
          }) => (
            <>
              <AlertError status={status} />

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="startDate">
                      <Form.Label>Periodo de</Form.Label>
                      <Form.Control
                          type="date"
                          name="startDate"
                          value={values.startDate}
                          onChange={handleChange}
                          className={`${getInputClasses(
                              touched,
                              errors,
                              "startDate"
                          )}`}
                      />
                      <ErrorMessage name="startDate">
                        {(msg) => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="endDate">
                      <Form.Label>Periodo al</Form.Label>
                      <Form.Control
                          type="date"
                          name="endDate"
                          value={values.endDate}
                          onChange={handleChange}
                          className={`${getInputClasses(
                              touched,
                              errors,
                              "endDate"
                          )}`}
                      />
                      <ErrorMessage name="endDate">
                        {(msg) => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="state">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                          as="select"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          className={`${getInputClasses(
                              touched,
                              errors,
                              "state"
                          )}`}
                      >
                        <option value="">SELECCIONA UNA OPCIÓN...</option>
                        {states && states.map( ({name}, idx) => (
                            <option key={idx} value={name}>{name}</option>
                        ))}
                      </Form.Control>
                      <ErrorMessage name="state">
                        {(msg) => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                      <Spinner
                          as="span"
                          animation="border"
                          variant="dark"
                          size="sm"
                      />
                  )}
                  Filtrar
                </Button>
              </Form>
            </>
        )}
      </Formik>

      <Table responsive striped bordered hover className="mt-5">
        <thead>
        <tr>
          <th>Opciones</th>
          <th>Medico</th>
          <th>Especialidad</th>
          <th>Fecha hora solicitud (DD/MM/AAAA HH:MM:SS)</th>
        </tr>
        </thead>
        <tbody>
          {Array.isArray(medics) && medics?.map((currMedic, i) => {
            return (
                <tr key={i}>
                  <td>
                    <Link className="btn btn-primary" to={`/solicitudes_registros_medicos_detalles/${currMedic.id}`}>
                      Ir
                    </Link>
                  </td>
                  <td>{currMedic.f_lastname} {currMedic.m_lastname} {currMedic.name}</td>
                  <td>{currMedic.specialty}</td>
                  <td>{moment.utc(currMedic.sent_authorize_at).format('DD/MM/YYYY H:mm:ss A')}</td>
                </tr>
            )
          })}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

  export default MedicSelfRegistrations;
